import React, { useState, useEffect } from 'react';
import GoogleMap from '../GoogleMap';

import { StyledContainer, StyledLink } from './styles';

import MapContainerInfo from '../MapContainerInfo';

const MapContainer = props => {
 
  const [locationView, changeLocation] = useState('london');
  const londonLatLng = { lat: 51.50788614475553, lng: -0.09491010215249314 };
  const newyorkLatLng = { lat: 40.708951, lng: -74.011997 };
  const chicagoLatLng = { lat: 41.877181, lng: -87.633865 };
  
  const dubaiLatLng = { lat: 25.0925548,lng:55.1501567 };


 
  let locationCenter;

  if (locationView === 'london') {
    locationCenter = londonLatLng;
  } else if (locationView === 'newyork') {
    locationCenter = newyorkLatLng;
  } else if (locationView === 'chicago') {
    locationCenter = chicagoLatLng;
  } else if (locationView === 'dubai') {
    locationCenter = dubaiLatLng;
  }else{
    locationCenter = londonLatLng;
  }

  return (
    <StyledContainer>
      {props.isIE && locationView === 'london' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/place/Riverside+House,+2A+Southwark+Bridge+Rd,+London+SE1+9HA/@51.5077259,-0.0970988,17z/data=!3m1!4b1!4m5!3m4!1s0x487604a9c70c9f43:0xb5ca59f2f86b9f8c!8m2!3d51.5077259!4d-0.0949101"
        >
         
        </StyledLink>
      )}
      {props.isIE && locationView === 'newyork' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/dir//Four+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA/@40.7102889,-74.0144788,17z/data=!4m17!1m7!3m6!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2sFour+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA!3b1!8m2!3d40.7102889!4d-74.0122901!4m8!1m0!1m5!1m1!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2m2!1d-74.0122901!2d40.7102889!3e2"
        >
          
        </StyledLink>
      )}
      {props.isIE && locationView === 'chicago' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/place/330+S+Wells+St/@41.8771366,-87.638739,17z/data=!4m7!3m6!1s0x880e2cbe6de71fa1:0xc00fef630e421440!4b1!8m2!3d41.8771327!4d-87.6338681!16s%2Fg%2F11c26gr9qv?utm_campaign=tt-sps&coh=209934&entry=tts"
        >
          
        </StyledLink>
      )}
      {props.isIE && locationView === 'dubai' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/dir//Dubai+Media+City+-+Dubai+-+United+Arab+Emirates/@25.0962082,55.1163897,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x3e5f6b43c675c243:0x7dfdb7a273cf2c58!2m2!1d55.1575893!2d25.0961362!3e2?entry=ttu"
        >
          
        </StyledLink>
      )}
      {!props.isIE && <GoogleMap defaultCenter={locationCenter} />}
      <MapContainerInfo
        locationView={locationView}
        changeLocation={changeLocation}
      />
    </StyledContainer>
  );
};

export default MapContainer;
