import React from 'react';
import WindowWidthContext, { useWindowWidth } from '../../context/WindowWidthContext';
import { useSpring } from 'react-spring';

import RightArrow from '../../images/map-arrow-right.svg';
import LeftArrow from '../../images/map-arrow-left.svg';

import theme from '../Layout/theme';
import {
  StyledLocationPhone,
  StyledLocationTitle,
  StyledLocationInfo,
  StyledOtherOfficeContainer,
  StyledOtherOfficeLon,
  StyledOtherOfficeNY,
  StyledOtherOfficeChicago,
  StyledOtherOfficeDubai,
  StyledNextArrow,
  StyledBackArrow,
  StyledLocation,
  StyledLocationSplitInfo,
  LocationPanel,
} from './styles';

const MapContainerInfo = props => {
  let locationViewInfo;

  locationViewInfo =
    props.locationView === 'london' ? (
      <StyledLocationInfo>
       Riverside House
        <br />
        2A Southwark Bridge Road
        <br />
        London SE1 9HA
        <br />
        <a href="tel:+44-207-940-7310">T: +44 207 940 7310</a>
        <br />
        E: <a href="mailto:info@cherrythinking.com">info@cherrythinking.com</a>
      </StyledLocationInfo>
    ) : props.locationView === 'newyork' ? (
      <StyledLocationInfo>
        4 World Trade Center,
        <br />
        150 Greenwich Street, 48<sup>th</sup> Floor
        <br />
        New York, NY 10007
        <br />
        <a href="tel:+1-646-701-0044">T: +1 646 7010044</a>
        <br />
        E: <a href="mailto:info@cherrythinking.com">info@cherrythinking.com</a>
      </StyledLocationInfo>
     ) : props.locationView === 'chicago' ? (
      <StyledLocationInfo>
        330 South Wells 4th Floor,
        <br />
        Chicago, IL 60606
        <br />
        <a href="tel:+1-312-248-8872">T: +1 312-248-8872</a>
        <br />
        E:{' '}
        <a href="mailto:info@cherrythinking.com">
          info@cherrythinking.com
        </a>
      </StyledLocationInfo>

      ) : props.locationView === 'dubai' ? (
        <StyledLocationInfo>
           Office 207/208,
          <br />
          Thomson Reuters Building 01
          <br />
          Dubai Media City
          <br />
          PO BOX 502420
          <br />
          E:{' '}
          <a href="mailto:info@cherrythinking.com">
            info@cherrythinking.com
          </a>
        </StyledLocationInfo>
    ) : (
      <StyledLocationInfo>
        An Error has occurred, please reload the page.
      </StyledLocationInfo>
    );

    const {width} = useWindowWidth();

  return (
        <>
          {width < 768 && (
            <StyledLocationPhone>
              <StyledLocationTitle>
              {props.locationView === 'london' &&  'London'}
              {props.locationView === 'newyork' &&  'New York'}
              {props.locationView === 'chicago' && 'Chicago'}
              {props.locationView === 'dubai' && 'Dubai'}
    
              </StyledLocationTitle>
              {locationViewInfo}
              <StyledOtherOfficeContainer locationView={props.locationView}>
                {props.locationView === 'london' && (
                  <StyledOtherOfficeLon>
                    <p
                      onClick={() =>
                     props.changeLocation('newyork')
                    }
                    >                   
                    
                     New York <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>

                  <p
                      onClick={() =>
                     props.changeLocation('chicago')
                    }
                    >                   
                    
                     Chicago <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                  <p
                      onClick={() =>
                     props.changeLocation('dubai')
                    }
                    >                   
                    
                     Dubai <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                  </StyledOtherOfficeLon>
                )}
                

                {props.locationView === 'newyork' &&  (
                  <StyledOtherOfficeNY>
                    <p
                      onClick={() =>
                     props.changeLocation('london')
                    }
                    >                   
                    
                     London <StyledNextArrow><RightArrow /></StyledNextArrow>
                    </p>

                    <p
                        onClick={() =>
                      props.changeLocation('chicago')
                      }
                      >                   
                      
                      Chicago <StyledNextArrow><RightArrow /></StyledNextArrow>
                    </p>
                    <p
                        onClick={() =>
                      props.changeLocation('dubai')
                      }
                      >                   
                      
                      Dubai <StyledNextArrow><RightArrow /></StyledNextArrow>
                    </p>
                  </StyledOtherOfficeNY>
                )}


                {props.locationView === 'chicago' &&  (
                  <StyledOtherOfficeChicago>
                    <p
                      onClick={() =>
                     props.changeLocation('london')
                    }
                    >                   
                    
                     London <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                    <p
                      onClick={() =>
                     props.changeLocation('newyork')
                    }
                    >                   
                    
                     New York <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>

                  
                  <p
                      onClick={() =>
                     props.changeLocation('dubai')
                    }
                    >                   
                    
                     Dubai <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                  </StyledOtherOfficeChicago>
                )}  

                {props.locationView === 'dubai' &&  (
                  <StyledOtherOfficeDubai >
                    <p
                      onClick={() =>
                     props.changeLocation('london')
                    }
                    >                   
                    
                     London <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                    <p
                      onClick={() =>
                     props.changeLocation('newyork')
                    }
                    >                   
                    
                     New York <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>

                  
                  <p
                      onClick={() =>
                     props.changeLocation('chicago')
                    }
                    >                   
                    
                     Chicago <StyledNextArrow><RightArrow /></StyledNextArrow>
                  </p>
                  </StyledOtherOfficeDubai>
                )}  

                
              </StyledOtherOfficeContainer>
            </StyledLocationPhone>
          )}

          {width >= 768 && (
            <StyledLocation>
              <LocationPanel>
                <StyledLocationSplitInfo
                 
                  background={
                    props.locationView === 'london'
                      ? theme.staticLinearGradientLeft
                      : 'white'
                  }
                  color={props.locationView === 'london' ? 'white' : '#a5a5a5'}
                  gridcol="1 / span 1"
                  msgridcol="1"
                  msgridspan="1"
                  zindex={props.locationView === 'london' ? '2' : '1'}
                  onClick={() =>
                    props.changeLocation('london')
                  }
                >
                  <div>
                    <StyledLocationTitle>London</StyledLocationTitle>
                    <StyledLocationInfo>
                      Riverside House
                      <br />
                      2A Southwark Bridge Road
                      <br />
                      London SE1 9HA
                      <br />
                      <a href="tel:+44-207-940-7310"> T: +44 207 940 7310</a>
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>
              <LocationPanel>
                <StyledLocationSplitInfo
                 
                  background={
                    props.locationView === 'newyork'
                      ? theme.staticLinearGradientRight
                      : 'white'
                  }
                  gridcol="2 / span 1"
                  msgridcol="2"
                  msgridspan="1"
                  color={props.locationView === 'newyork' ? 'white' : '#a5a5a5'}
                  zindex={props.locationView === 'newyork' ? '2' : '1'}
                  onClick={() =>
                    props.changeLocation('newyork')

                  }
                >
                  <div>
                    <StyledLocationTitle>New York</StyledLocationTitle>
                    <StyledLocationInfo>
                      4 World Trade Center,
                      <br />
                      150 Greenwich Street, 48<sup>th</sup> Floor
                      <br />
                      New York, NY 10007
                      <br />
                      <a href="tel:+1-646-701-0044">T: +1 646 7010044</a>
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>

              

              <LocationPanel>
                <StyledLocationSplitInfo
                 
                  background={
                    props.locationView === 'chicago'
                      ? theme.staticLinearGradientRight
                      : 'white'
                  }
                  gridcol="2 / span 1"
                  msgridcol="2"
                  msgridspan="1"
                  color={props.locationView === 'chicago' ? 'white' : '#a5a5a5'}
                  zindex={props.locationView === 'chicago' ? '2' : '1'}
                  onClick={() =>
                    props.changeLocation('chicago')
   
                  }
                >
                  <div>
                    <StyledLocationTitle>Chicago</StyledLocationTitle>
                    <StyledLocationInfo>
                      330 South Wells 4th Floor,
                      <br />
                      Chicago, 
                      
                      <br />
                      IL 60606
                      <br />
                      <a href="tel:+1-312-248-8872">T: +1 312-248-8872</a>
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>


              <LocationPanel>
                <StyledLocationSplitInfo
              
                  background={
                    props.locationView === 'dubai'
                      ? theme.staticLinearGradientRight
                      : 'white'
                  }
                  gridcol="2 / span 1"
                  msgridcol="2"
                  msgridspan="1"
                  color={props.locationView === 'dubai' ? 'white' : '#a5a5a5'}
                  zindex={props.locationView === 'dubai' ? '2' : '1'}
                  onClick={() =>
                    props.changeLocation('dubai')
                   
                  }
                >
                  <div>
                    <StyledLocationTitle>Dubai</StyledLocationTitle>
                    <StyledLocationInfo>
                      Office 207/208,
                      <br />
                      Thomson Reuters Building 01
                      <br />
                      Dubai Media City
                      <br />
                      PO BOX 502420
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>


            </StyledLocation>
          )}
        </>
  );
};

export default MapContainerInfo;
